import React, { useState } from "react";
import Dashboard from "./pages/Dashboard";
import Login from './pages/auth/login'
const App = () => {
  const [loginStatus,setLoginStatus ] = useState(false);

  return (
    <div>
      {/* <Dashboard /> */}
      {loginStatus == true?<Dashboard /> : <Login setLoginStatus={setLoginStatus}/>}
     
    </div>
  );
};

export default App;
