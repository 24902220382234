import React, { useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
// import Button from "react-bootstrap/Button";
import { InteractionType } from '@azure/msal-browser';
import ProfileContent from "./ProfileContent";
import MicrosoftPNG from './microsoft.png'

const App = ({setLoginStatus}) => {
  const request = {
    scopes: ["User.Read"],
    prompt: 'select_account'
  }
  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);

  useEffect(() => {
    if(result){
      console.log(result);
    }
}, [result]);

  const { accounts } = useMsal();
  if(accounts.length !=0 ){
    setLoginStatus(true)
  }
  else{
    setLoginStatus(false)
  }
  function handleLogin() {
    login(InteractionType.Redirect, request);
  }
  useEffect(()=>{
   
   
  },[])
  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {accounts.map((account) => {
          console.log({account})
          return <div key={account.homeAccountId}><ProfileContent homeId={account.homeAccountId} name={account.name }  /></div>
        })}

      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <p>No users are signed in!</p> */}
      </UnauthenticatedTemplate>
      {/* <div onClick={handleLogin} style={{justifyContent:"center",display:"flex",textAlign:"center",height:"100vh"}}>
        <div>
        Sign in new user
        </div>
      </div> */}
       <div class="container" style={{ height: "100vh",display: "flex",alignItems: "center",justifyContent:"center"}}>
        <div class="item" onClick={handleLogin} style={{display: "flex",cursor:"pointer",alignItems: "center",justifyContent:"center"}}>
            <img style={{width:50}} src={MicrosoftPNG}/>
            <samp style={{paddingTop:10,paddingLeft:10}}>
             Sign in with Microsoft
            </samp>
           </div>
    </div>
      
    </React.Fragment>
  );
}

export default App;