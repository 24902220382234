import axios from "axios";
import { API_URL } from "../constants/Statics";

const instance = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// instance.defaults.headers.common['language'] = 'en';
if (localStorage.accessToken) {
  const JWT_token = localStorage.accessToken;
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

instance.interceptors.request.use(async (config) => {
  const JWT_token = localStorage.accessToken;

  config.headers.common["access_token"] = `Bearer ${JWT_token}`;
  return config;
});

instance.interceptors.request.use(null, (error) => {
  alert(error.status);
  if (error.status === 500) {
    window.location.href = "/";
  }
  return Promise.reject(error);
});

export default instance;
