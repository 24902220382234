import styled from "styled-components/macro";
import backImg from "../assets/backImage.jpg";

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  color: #000;
  line-height: 1.5;
  font-weight: 500;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const ProfileHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: #ffffff; */
  background-color: #eff2f5;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  position: relative;
`;

export const CardContainer = styled.div`
  width: 30%;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset; */
  /* padding: 0px 0 0 20px; */
  border-right: 0.8px solid lightgray;
  background-color: #ffffff;

  color: #000;
  @media (max-width: 750px) {
    width: 100%;

    display: ${({ chatSelected }) => (chatSelected ? "none" : "block")};
  }
`;

export const InnerContainer = styled.div`
  background-color: #eff2f5;
  height: 100vh;
  /* height: 500px; */
  width: 70%;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  /* flex: 1; */
  flex-direction: column;
  /* overflow: hidden; */
  @media (max-width: 750px) {
    width: 100%;

    display: ${({ chatSelected }) => (chatSelected ? "flex" : "none")};
  }
`;
export const HeaderContainer = styled.div`
  width: 100%;
  background-color: #eff2f5;
  border-right: 0.8px solid lightgray;
  /* border-bottom: 1px solid grey;
  border-right: 1px solid grey; */
  ${"" /* border-right: 1px solid grey; */}
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 15px;
  background-color: #eff2f5;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 20px;
`;
export const Avatar = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;

export const Typography = styled.div`
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.48px;
  line-height: 28px;
  color: black;
`;

export const Profileinfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WelcomePage = styled.div`
  display: grid;
  text-align: center;
  /* margin-top: 30%; */
  justify-items: center;
  align-content: stretch;
  background-color: #eff2f5;
`;

export const ProfilePic = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 10px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const SelectedProfile = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
export const BottomCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background: #eff2f5; */
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
`;
export const FirstBottom = styled.div`
  width: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SecondBottom = styled.div`
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ThirdBottom = styled.div`
  /* width: 5%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  button {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
  }

  img {
    height: 30px;
    width: 30px;
  }
`;
export const MessageInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  /* height: 60px;
  width: 791px; */
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
`;

export const SendButton = styled.button`
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  cursor: pointer;
`;

export const EmojiImage = styled.img`
  height: 23px;
  width: 23px;
  cursor: pointer;
`;

export const EmojiPickerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const PlusBox = styled.div`
  position: relative;
`;
export const PlusImage = styled.img`
  width: 60px;
  height: 60px;
  ${"" /* margin-left: 10px; */}

  border-radius: 40%;
  /* margin-top: 10px; */
  cursor: pointer;
`;
export const SearchImage = styled.img`
  width: 28px;
  height: 28px;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 40%;
  opacity: 0.2;
  cursor: pointer;
`;
export const AudioImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 40%;
  cursor: pointer;
`;

export const CustomListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e9edef;
  cursor: pointer;
  &:hover {
    background-color: #f5f6f6;
  }
`;
export const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const ContactName = styled.div`
  font-size: 0.9rem;
  letter-spacing: 0.36px;
  line-height: -28px;
  color: #111b21;
  font-weight: 400;
`;
export const LastText = styled.span`
  font-size: 0.8rem;
  padding: 5px 0 0 0;
  color: ${({ isUnread }) => (isUnread ? "#b2bbc0" : "#888")};
  font-weight: 700;
  margin-right: 5px;
`;

export const LastTextTime = styled.span`
  font-weight: 500;
  /* padding: 15px 0 0 0; */
  margin-right: 24px;
  margin-top: ${({ hasUnread }) => (hasUnread ? "10px" : "10px")};
  font-size: 0.8rem;
  color: #aaa;
`;
export const SearchIcon = styled.img`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;
export const EllipsisIcon = styled.img`
  cursor: pointer;
  height: 17px;
  width: 17px;
`;
export const UsersIcon = styled.img`
  height: 20px;
  width: 20px;

  cursor: pointer;
`;

export const MessagesHeading = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 6px;
  color: white;
`;
export const StatusText = styled.span`
  margin-top: 2px;
  font-size: 15px;
  color: #aaa;
`;
export const Profilename = styled.div`
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.36px;
  line-height: 28px;
`;
export const RightIcon = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 28px;
  position: relative;
  ${
    "" /* margin-left: ${({ windowWidth }) => (windowWidth < 750 ? "20%" : "0")}; */
  }
`;
export const StrokeImage = styled.img`
  position: relative;
  height: 22px;
  width: 22px;
  cursor: pointer;
  `;
export const VectorImage = styled.img`
  position: relative;
  height: 22px;
  width: 22px;
  cursor: pointer;
  `;
export const SearchI = styled.img`
  position: relative;
  height: 22px;
  width: 22px;
  cursor: pointer;
`;

export const ContactGreenDot = styled.div`
  background-color: #08ad2c;
  border-radius: 50%;
  top: 34%;
  margin-left: 3%;

  display: block;
  width: 10px;
  height: 10px;
  position: fixed;
  border: 1px solid;
  border-color: #ffffff;
`;
export const RightList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const UnreadIcon = styled.div`
  background-color: #24d366;
  border-radius: 50%;
  height: 18px;
  /* position: absolute; */
  width: 18px;
  display: ${({ hasUnread }) => (hasUnread ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.6rem;
  margin-top: 5px;
`;
export const Status = styled.div`
  color: #aaa;
  font-size: 0.8rem;
`;
export const Middle = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;
export const LeftCard = styled.div`
  /* height: calc(100% - 272px); */
`;
export const ProfileBox = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  img {
    height: 50px;
    border-radius: 50%;
    width: 50px;
    object-fit: cover;
  }
`;
export const ContentBox = styled.div`
  width: 83%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ProfileInfoContainer = styled.div`
  height: 100vh;
  overflow-y: scroll;
  display: ${({ profileInfoOpen }) => (profileInfoOpen ? "block" : "none")};
  width: 500px;
  background-color: #181818;
  position: relative;
  
  ::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #cfd0d1;
    /* background: linear-gradient(#1f97fc , #f04c71); */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  /* Set the styles for the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
  }

  /* Set the styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
`;
export const CloseIcon = styled.img`
  top: 105px;
  left: 39px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
export const ContactDetails = styled.div`
  height: 982px;
  position: relative;
  width: 281px;
  padding: 100px 61px 64px 39px;
  @media (max-width: 750px) {
    padding-left: 159px;
  }
`;
export const Frame = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  padding: 47px 23px 0 0;
`;

export const ContactProfilePic = styled.img`
  height: 140px;
  width: 140px;
`;
export const Contactname = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;

  letter-spacing: 0.48px;
`;
export const Contactphone = styled.div`
  color: #ffffff;
  font-size: 18px;
  margin-top: -23px;
  font-weight: 400;
  left: 0;

  letter-spacing: 0.36px;
  opacity: 0.55;

  top: 0;
`;
export const Line = styled.img`
  padding-top: 9px;
`;
export const MiddleFrame = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  width: 280px;
  padding: 37px 23px 0 0;
`;
export const Seeall = styled.img`
  margin-left: auto;
`;
export const ImageBox = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  flex-direction: row;
  padding: 24px 0 0 0;
`;
export const Image1 = styled.img`
  height: 100px;
  width: 82px;
`;
export const Image2 = styled.img`
  height: 100px;
  width: 82px;
`;

export const LowerFrame = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 280px;
  padding: 37px 23px 0 0;
`;
export const StarredMsgHeading = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 280px;
  padding: 37px 23px 0 0;
`;
export const Image3Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-direction: row;
  position: relative;
`;

export const Image3 = styled.img`
  width: 82px;
  height: 100px;
`;

export const Imageinside3 = styled.img`
  position: absolute;
  bottom: 43px;
  right: 30px;
  height: 13px;
  width: 27px;
`;
// export const FileInfo = styled.div`
//   color: white;
//   align-items: flex-start;
//   display: inline-flex;
//   flex-direction: column;
//   gap: 16px;
//   position: relative;
// `;
export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 28px 0 0 0;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FileIconContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  height: 42px;
  left: 0;
  position: relative;
  top: 0;
  width: 42px;
`;
export const FileIcon = styled.img`
  left: 9px;
  position: absolute;
  padding: 0px;
  top: 7px;
  width: 25px;
  height: 27px;
`;

export const FileDetails = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
  display: flex;

  flex-direction: column;
`;

export const Box = styled.img`
  background-color: #f3f1f4;
  border-radius: 12px;
  height: 42px;

  width: 42px;
`;
export const FileName = styled.div`
  color: #ffffff;
  letter-spacing: 0.32px;
  font-size: 16px;
  font-weight: 400;
`;

export const FileSize = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.28px;
  opacity: 0.5;
`;
export const MiddleLeft = styled.div`
  display: flex;
  width: 422px;
  padding: 28px 0 0 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const Headdiv = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
`;
export const OnlineHeading = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
`;
export const Onlineno = styled.div`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  width: fit-content;
`;

export const SeeallOnline = styled.div`
  font-size: 18px;
  font-weight: 500;
  flex: 0 0 auto;
  position: relative;
  color: white;
`;
export const OnlineProfileBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 422px;
  margin-top: 12px;
`;
export const LMiddle = styled.div`
  border-bottom: 1px solid grey;
  padding-bottom: 28px;
  height: 162px;
`;

export const BackButton = styled.img`
  background-color: white;

  position: absolute;
  top: 105px;
  left: 39px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
export const CancelButton = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const MenuOptions = styled.div`
  top: -73px;
  position: absolute;
  color: white;
  background-color: #333; /* Background color for the menu */
  border-radius: 8px;
  padding: 10px;
  display: ${({ menuOpen }) => (menuOpen ? "block" : "none")};
  flex-direction: column;

  button {
    margin-bottom: 8px;
    padding: 8px;
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: black; /* Background color on hover */
    }
  }
`;
export const ContactListDiv = styled.div`
  overflow-y: scroll;
  height: 83vh;

  ::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #cfd0d1;
    /* background: linear-gradient(#1f97fc , #f04c71); */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  /* Set the styles for the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
  }

  /* Set the styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
  @media (max-width: 750px) {
    /* overflow: auto; */
  }
`;
export const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const PlusB = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const ZoomedProfilePic = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
`;
export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e9edef;
`;
export const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eff2f5;
  padding: 12px 10px;
  border-radius: 8px;
`;
export const SearchIconBox = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 18px;
    width: 18px;
  }
`;
export const SearchInputBox = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    background-color: transparent;
    font-size: 0.8rem;
    border: none;
    outline: none;

    ::placeholder {
      color: #858c8d;
      font-size: 0.9rem;
    }
  }
`;
export const MessgageBox = styled.div`
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-image: url(${backImg});
  background-size: cover; /* Optional: Specifies how the background image should be sized */
  background-repeat: no-repeat; /* Optional: Prevents the background image from repeating */
  background-position: center;
  /* padding: 2rem 0; */

  ::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #cfd0d1;
    /* background: linear-gradient(#1f97fc , #f04c71); */
    border-radius: 3px; /* Rounded corners for the thumb */
  }

  /* Set the styles for the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
  }

  /* Set the styles for the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
`;
export const MessageRow = styled.div`
  width: 100%;
  display: flex;
  background-color: lightpink;
  height: 20px;
  margin-bottom: 4rem;
`;
