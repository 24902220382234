import Document from './assets/file/Document.png'
import Image from './assets/file/Image.png'
import music from './assets/file/music.png'
import Rectangle1 from './assets/Rectangle1.png'
import Rectangle2 from './assets/Rectangle2.png'
import user1 from './assets/profile/user1.png'
import user2 from './assets/profile/user2.png'
import user3 from './assets/profile/user3.png'
import user4 from './assets/profile/user4.png'
import user5 from './assets/profile/user5.png'
import user6 from './assets/profile/user6.png'
import elon from './assets/profile/elon.jpeg'
import Online1 from './assets/profile/Online1.png'
import Online2 from './assets/profile/Online2.png'
import Online3 from './assets/profile/Online3.png'
import Online4 from './assets/profile/Online4.png'
import Online5 from './assets/profile/Online5.png'

export const contactList = [
  {
    id: 1,
    name: "Catherine",
    profilePic: user1,
    lastText: "Hey Man",
    lastTextTime: "12:58 ",
    status: "online",
    ShowOnline: "Off",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 2,
    name: "Moana",
    profilePic: user2,
    lastText: `Yo bro I got some info `,
    lastTextTime: "12:45 ",
    status: "offline",
    ShowOnline: "Off",
    seen: "unread",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 3,
    name: "Elon Bezoz",
    profilePic: user3,
    lastText: "Where is my bag?",
    lastTextTime: "12:30 ",
    status: "offline",
    ShowOnline: "Off",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 4,
    name: "Jeff Musk",
    profilePic: user4,
    lastText: "Travelling to space",
    lastTextTime: "12:00 ",
    ShowOnline: "Off",
    status: "offline",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 5,
    name: "Jegaer ",
    profilePic: user5,
    lastText: "All okay?",
    lastTextTime: "05:00 ",
    status: "offline",
    ShowOnline: "Off",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 6,
    name: "Nagertg",
    profilePic: user6,
    lastText: "Travelling to delhi",
    lastTextTime: "08:00 ",
    ShowOnline: "Off",
    status: "offline",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 7,
    name: "rohan ",
    profilePic: elon,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    status: "offline",
    seen: "read",
    ShowOnline: "Off",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 8,
    name: "roha ",
    profilePic: Online1,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    status: "online",
    ShowOnline: "On",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 9,
    name: "rohit ",
    profilePic: Online2,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    ShowOnline: "On",
    status: "online",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 10,
    name: "rohan ",
    profilePic: Online3,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    ShowOnline: "On",
    status: "online",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 11,
    name: "rohan ",
    profilePic: Online4,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    status: "online",
    ShowOnline: "On",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
  {
    id: 12,
    name: "rohan ",
    profilePic: Online5,
    lastText: "Travelling to space",
    lastTextTime: "06:00 ",
    status: "online",
    ShowOnline: "On",
    seen: "read",
    number: "+62 8224 2384",
    files: [
      {
        filename: "Citation",
        filesize: "1.2 MB",
        fileicon: Document,
      },
      {
        filename: "sample.png",
        filesize: "1 MB",
        fileicon: Image,
      },
      {
        filename: "sample2.mp3",
        filesize: "2 MB",
        fileicon: music,
      },
      {
        filename: "sample3.png",
        filesize: "1.8 MB",
        fileicon: Image,
      },
      {
        filename: "sample4.png",
        filesize: "1.5 MB",
        fileicon: Document,
      },
    ],
  },
];
export const messagesList = [
  {
    id: 1,
    messageType: "TEXT",
    text: "Hey Man ",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 2,
    messageType: "IMAGE",
    image: [Rectangle1, Rectangle2],
    senderID: 1,
    addedOn: "05:15",
  },
  {
    id: 3,
    messageType: "TEXT",
    text: "Hey, What's up? ",
    senderID: 0,
    addedOn: "12:01 ",
  },
  {
    id: 4,
    messageType: "TEXT",
    text: "All Good, What about you? ",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 5,
    messageType: "TEXT",
    text: "I'm good as well",
    senderID: 0,
    addedOn: "12:00 ",
  },
  {
    id: 6,
    messageType: "TEXT",
    text: "Great 😁",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 7,
    messageType: "TEXT",
    text: "okay ",
    senderID: 0,
    addedOn: "12:00 ",
  },
  {
    id: 8,
    messageType: "VIDEO",
    video: [],
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 1,
    messageType: "TEXT",
    text: "Hey Man ",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 2,
    messageType: "IMAGE",
    image: [Rectangle1, Rectangle2],
    senderID: 1,
    addedOn: "05:15",
  },
  {
    id: 3,
    messageType: "TEXT",
    text: "Hey, What's up? ",
    senderID: 0,
    addedOn: "12:01 ",
  },
  {
    id: 4,
    messageType: "TEXT",
    text: "All Good, What about you? ",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 5,
    messageType: "TEXT",
    text: "I'm good as well",
    senderID: 0,
    addedOn: "12:00 ",
  },
  {
    id: 6,
    messageType: "TEXT",
    text: "Great 😁",
    senderID: 1,
    addedOn: "12:00 ",
  },
  {
    id: 7,
    messageType: "TEXT",
    text: "okay ",
    senderID: 0,
    addedOn: "12:00 ",
  },
  {
    id: 8,
    messageType: "VIDEO",
    video: [],
    senderID: 1,
    addedOn: "12:00 ",
  },
];
