import React, { useState } from "react";
import "./MessageList.css";
import FullScreenImage from "./FullScreenImage";
import * as DE from "./DashEle";
import { STATIC_URL } from "../constants/Statics";
import moment from "moment";

const MessageList = ({ messageList }) => {
  console.log(messageList, "messageList");
  const [profileZoomViewOpen, setProfileZoomViewOpen] = useState(false);

  const [zoomedImage, setZoomedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
    setProfileZoomViewOpen(true);
  };

  const handleCloseZoomView = () => {
    setZoomedImage(null);
    setProfileZoomViewOpen(false);
  };
  setTimeout(() => {
    let el = document.getElementById("chat-container");
    if (el && el.scrollHeight) {
      el.scrollTo(0, el.scrollHeight);
    }
  }, 100);
  return (
    <DE.MessgageBox id="chat-container">
      {messageList?.map((message) => (
        <div
          className={`MessageBubbleContainer ${
            message.messageAction !== "receive" ? "isUsers" : ""
          }`}
          style={{
            alignItems:
              message.messageAction !== "receive" ? "flex-end" : "flex-start",
          }}
          key={message.id} // Add a unique key for each message
        >
          {message.messageType === "text" ? (
            <div
              className={`MessageBubble ${
                message.messageAction !== "receive" ? "isUsers" : ""
              }`}
            >
               {
                 message.messageAction !== "receive" ? 
                 null:<span style={{padding:"0px",paddingRight:"5px",fontSize:11}}>
                 {moment(message.createdAt).format("HH:mm")}
                </span>
               }
               
              {message.message}
              {
                 message.messageAction === "receive" ? 
                 null:<span style={{padding:"0px",paddingLeft:"5px",fontSize:11}}>
                 {moment(message.createdAt).format("HH:mm")}
                </span>
               }
              <div
                className={`MessageTime ${
                  message.messageAction !== "receive" ? "isUsers" : ""
                }`}
              >
                {message.addedOn}
              </div>
              {message.messageAction !== "receive" ? (
                <div className="MsgSeenTick">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                  >
                    <path
                      d="M1 7.74641L5.09603 11.8424L15.9385 1"
                      stroke="#CE5988"
                    />
                    <path
                      d="M5.18311 7.96525L10.9657 2.18262"
                      stroke="#CE5988"
                    />
                  </svg>
                  
                </div>
              ) : null}
            </div>
          ) : message.messageType === "image" ? (
            <div
              className={`MessageBubble ${
                message.messageAction !== "receive" ? "isUsers" : ""
              } hasImage`}
            >
              {/* {message.image.map((image, index) => ( */}
              <img
                className="msgimage"
                // key={index}
                src={`${STATIC_URL}${message.message}`}
                // alt={`Message Image ${index}`}
                alt={`Message Image test`}
                controls
                onError={(e) => {
                  console.error("Image load error:", e);
                }}
                onClick={() => handleImageClick(STATIC_URL + message.message)}
              />
              {/* ))} */}
              {profileZoomViewOpen && (
                <FullScreenImage
                  imageUrl={zoomedImage}
                  onClose={handleCloseZoomView}
                />
              )}
              <div
                className={`MessageTime ${
                  message.messageAction !== "receive" ? "isUsers" : ""
                }`}
              >
                {message.addedOn}
              </div>
              {message.messageAction !== "receive" ? (
                <div className="MsgSeenTick">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                  >
                    <path
                      d="M1 7.74641L5.09603 11.8424L15.9385 1"
                      stroke="#CE5988"
                    />
                    <path
                      d="M5.18311 7.96525L10.9657 2.18262"
                      stroke="#CE5988"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
          ) : message.messageType === "VIDEO" &&
            Array.isArray(message.video) &&
            message.video.length > 0 ? (
            <div
              className={`MessageBubble ${
                message.messageAction !== "receive" ? "isUsers" : ""
              } hasVideo`}
            >
              {message.video.map((video, index) => (
                <video
                  className="msgvideo"
                  key={index}
                  src={video}
                  alt={`Message Video ${index}`}
                  controls
                />
              ))}
              <div
                className={`MessageTime ${
                  message.messageAction !== "receive" ? "isUsers" : ""
                }`}
              >
                {message.addedOn}
              </div>
              {message.senderID === 0 ? (
                <div className="MsgSeenTick">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                  >
                    <path
                      d="M1 7.74641L5.09603 11.8424L15.9385 1"
                      stroke="#CE5988"
                    />
                    <path
                      d="M5.18311 7.96525L10.9657 2.18262"
                      stroke="#CE5988"
                    />
                  </svg>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ))}
    </DE.MessgageBox>
  );
};

export default MessageList;
