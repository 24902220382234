import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { contactList, messagesList } from "../mockData";
import EmojiPicker from "emoji-picker-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Image from "./Image";
import MessageList from "./MessageList";
import FullScreenImage from "./FullScreenImage";
import profilePic from "../assets/download.png";
import userIcon from "../assets/3-User.png";
import searchIcon from "../assets/search.png";
import dotsIcon from "../assets/dots-three.png";
import strokeIcon from "../assets/phone-fill.png";
import vectorIcon from "../assets/video-camera-fill.png";
import backbutton from "../assets/backbutton.jpg";
import LineICon from "../assets/Line.png";
import groupIcon from "../assets/smiley-bold.png";
import plusIcon from "../assets/plus-bold.png";
import audioIcon from "../assets/microphone-fill.png";
import sendIcon from "../assets/send.png";
import closeIcon from "../assets/close.png";
import SeeallIcon from "../assets/Seeall.png";
import Rectangle15 from "../assets/Rectangle15.png";
import Rectangle16 from "../assets/Rectangle16.png";
import Rectangle17 from "../assets/Rectangle17.png";
import plusTenIcon from "../assets/plusTen.png";
import searchChats from "../assets/searchChat.png";
import groupChats from "../assets/groupChat.png";
import {
  Container,
  ProfileHeader,
  CardContainer,
  InnerContainer,
  HeaderContainer,
  Header,
  IconContainer,
  Avatar,
  Typography,
  Profileinfo,
  WelcomePage,
  ProfilePic,
  SelectedProfile,
  BottomCard,
  MessageInput,
  SendButton,
  EmojiImage,
  EmojiPickerContainer,
  PlusBox,
  PlusImage,
  SearchImage,
  AudioImage,
  CustomListItem,
  ListItemContent,
  ContactName,
  LastText,
  LastTextTime,
  SearchIcon,
  EllipsisIcon,
  UsersIcon,
  MessagesHeading,
  StatusText,
  Profilename,
  RightIcon,
  StrokeImage,
  VectorImage,
  SearchI,
  ContactGreenDot,
  RightList,
  UnreadIcon,
  Status,
  Middle,
  LeftCard,
  ProfileBox,
  ProfileInfoContainer,
  CloseIcon,
  ContactDetails,
  Frame,
  ContactProfilePic,
  Contactname,
  Contactphone,
  Line,
  MiddleFrame,
  Seeall,
  ImageBox,
  Image1,
  Image2,
  LowerFrame,
  StarredMsgHeading,
  Image3Container,
  Image3,
  Imageinside3,
  FileInfo,
  FileContainer,
  FileIconContainer,
  FileIcon,
  FileDetails,
  Box,
  FileName,
  FileSize,
  MiddleLeft,
  Headdiv,
  OnlineHeading,
  Onlineno,
  SeeallOnline,
  OnlineProfileBox,
  LMiddle,
  BackButton,
  CancelButton,
  MenuOptions,
  ContactListDiv,
  Profile,
  PlusB,
  ZoomedProfilePic,
  ContentBox,
  SearchContainer,
  SearchBox,
  SearchIconBox,
  SearchInputBox,
  FirstBottom,
  SecondBottom,
  ThirdBottom,
} from "./DashEle";
import axios from "../utiles/axios";
import userImg from "../assets/user.jpeg";
import { SocketContext } from "../socket";
import { getResponsiveTime } from "../utiles/helpers";
import moment from "moment";

const Dashboard = () => {
  const socket = useContext(SocketContext);
  const [selectedContact, setSelectedContact] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [pickerVisible, togglePicker] = useState(false);
  const [text, setText] = useState("");
  const [messageList, setMessageList] = useState([]);
  // const [profileInfoOpen, setProfileInfoOpen] = useState(contactList[0]);
  const [profileInfoOpen, setProfileInfoOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuOpen, setMenuOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [profileZoomViewOpen, setProfileZoomViewOpen] = useState(false);
  const [zoomedProfilePic, setZoomedProfilePic] = useState(null);
  const [messageText, setMessageText] = useState("");
  const messageContainerRef = useRef(null);
  useEffect(() => {
    // Check if the message container ref is available
    if (messageContainerRef.current) {
      // Scroll to the bottom of the message container
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messageList]);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleBackClick = () => {
    setSelectedContact(null);
  };
  const handleContactClick = (contact) => {
    setSelectedContact(contact);
  };

  const toggleProfileInfo = () => {
    setProfileInfoOpen(!profileInfoOpen);
    console.log("Profile Info Open:", profileInfoOpen);
  };
  const closeProfileInfo = () => {
    console.log("Close icon clicked");

    setProfileInfoOpen(false);
    console.log(profileInfoOpen);
  };

  const handleSearchInputChange = (event) => {
    const text = event.target.value;
    setSearchText(text);

    const filtered = contactList.filter((contact) =>
      contact.name.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredContacts(filtered);
  };
  // const handleBackClick = () => {
  //   setSelectedContact(null);
  // };

  const onEmojiClick = (event, emojiObj) => {
    setText(text + emojiObj.emoji);
    togglePicker(false);
  };

  const onEnterPress = async (event) => {
    if (event.key === "Enter") {
      const messages = [...messageList];
      messages.push({
        id: 0,
        messageType: "TEXT",
        text,
        senderID: 0,
        addedOn: "05:09PM",
      });
      setMessageList(messages);
      setText("");
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
      // let el = document.getElementById("chat-container");
      // if (el && el.scrollHeight) {
      //   el.scrollTo(0, el.scrollHeight + 50);
      // }
    }
  };
  // Dummy user profile data
  const dummyUserProfile = {
    name: "Kazuraba",
    status: "online",
    profilePic: profilePic,
  };
  const onlineContacts = contactList.filter(
    (contact) => contact.ShowOnline === "On"
  );
  const handleImageClick = () => {
    // Trigger the file input for image upload
    fileInputRef.current.accept = "image/*"; // Optional: Limit to image files
    fileInputRef.current.click();
    setMenuOpen(false);
  };

  const handleVideoClick = () => {
    // Trigger the file input for video upload
    fileInputRef.current.accept = "video/*"; // Optional: Limit to video files
    fileInputRef.current.click();
    setMenuOpen(false);
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = async (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected, do nothing
    }

    const fileType = selectedFile.type.split("/")[0]; // "image" or "video"

    if (fileType === "image") {
      const imageUrl = URL.createObjectURL(selectedFile);
      const newImageMessage = {
        id: messageList.length + 1, // Assign a unique ID based on the length of the current messageList
        messageType: "IMAGE",
        image: [imageUrl],
        senderID: 0,
        addedOn: "05:09PM",
      };
      setMessageList([...messageList, newImageMessage]);
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
    } else if (fileType === "video") {
      // Handle video upload
      const videoUrl = URL.createObjectURL(selectedFile);
      const newVideoMessage = {
        id: messageList.length + 1, // Assign a unique ID based on the length of the current messageList
        messageType: "VIDEO",
        video: [videoUrl],
        senderID: 0,
        addedOn: "05:09PM",
      };
      setMessageList([...messageList, newVideoMessage]);
      setTimeout(() => {
        let el = document.getElementById("chat-container");
        if (el && el.scrollHeight) {
          el.scrollTo(0, el.scrollHeight);
        }
      }, 100);
    }
    // setFileUploaded(true);
    e.target.value = null;
  };
  const openProfileZoomView = (profilePic) => {
    setZoomedProfilePic(profilePic);
    setProfileZoomViewOpen(true);
  };

  const closeProfileZoomView = () => {
    setZoomedProfilePic(null);
    setProfileZoomViewOpen(false);
  };

  const getUserList = async () => {
    try {
      const { data } = await axios.get("/users/chats");
      console.log(data, "momo");
      setFilteredContacts(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const getMessages = async (e) => {
    try {
      const { data } = await axios.get(`/user/${e}`);
      console.log(data.data, "messages");
      setMessageList(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const sendMessage = async () => {
    console.log(messageText, "");
    // alert("eer")

    try {
         if(messageText == "") return
         const { data } = await axios.post(`/user/send/textMessage`, {
          phoneNumber: selectedContact?.phoneNumber,
          text: text,
        });
        setText("")

        console.log({data})
    } catch (error) {
         alert(error)
    }

    // if (messageText) {
    //   try {
    //     const { data } = await axios.post(`/user/send/textMessage`, {
    //       phoneNumber: selectedContact?.phoneNumber,
    //       text: messageText,
    //     });
    //     console.log(data.data, "messages");
    //     // getMessages(selectedContact?.phoneNumber);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // } else {
    //   alert("Enter Message");
    // }
  };

  useEffect(() => {
    console.log('Off New')
    socket.on("new_message", (payload) => {
      console.log(payload, "Messages");
      getMessages(payload?.phoneNumber);
      getUserList();
    });
    // return () => {
    //   // before the component is destroyed
    //   // unbind all event handlers used in this component
    //   socket.off("new_message", () => {});
    //   socket.disconnect();
    // };
  }, [socket]);
  // useEffect(() => {
  //   console.log('Off New list')
  //   socket.on("new_user", (payload) => {
  //     console.log(payload, "Messages");
  //     getUserList();
  //   });
  //   // return () => {
  //   //   // before the component is destroyed
  //   //   // unbind all event handlers used in this component
  //   //   socket.off("new_message", () => {});
  //   //   socket.disconnect();
  //   // };
  // }, [socket]);

  // getUserList();
  

  console.log(selectedContact);

  return (
    <>
      <Container className="container">
        <CardContainer
          className="cardcontainer"
          chatSelected={selectedContact !== null}
        >
          <HeaderContainer>
            {" "}
            <Header className="header">
              <Image
                src={dummyUserProfile.profilePic}
                alt={dummyUserProfile.name}
                status={dummyUserProfile.status}
              />
              <IconContainer className="iconcontainer">
                <UsersIcon src={groupChats} />
                <SearchIcon src={searchChats} />
                <EllipsisIcon src={dotsIcon} />
              </IconContainer>
            </Header>
          </HeaderContainer>
          <LeftCard className="left-card">
            <SearchContainer>
              <SearchBox>
                <SearchIconBox>
                  <img src={searchChats} />
                </SearchIconBox>
                <SearchInputBox>
                  <input type="text" placeholder="Search or start new chat" />
                </SearchInputBox>
              </SearchBox>
            </SearchContainer>
            <ContactListDiv className="list-div">
              {filteredContacts.map((contact) => {
                console.log(contact.latest_message[0]?.timestamp);
                return (
                  <CustomListItem
                    className="customlist"
                    key={contact.id}
                    onClick={() => {
                      getMessages(contact?.phoneNumber);
                      setSelectedContact(contact);
                    }}
                  >
                    <ProfileBox className="profilebox">
                      <img
                        src={contact.profilePic || userImg}
                        alt={contact.userName}
                        // status={contact.status}
                      />
                    </ProfileBox>
                    <ContentBox>
                      <ListItemContent className="list-item-content">
                        <ContactName className="contact-name">
                          {contact.userName}
                        </ContactName>
                        {contact.seen === "unread" ? (
                          <LastText className="last-text" isUnread>
                            {contact.latest_message[0]?.message}
                          </LastText>
                        ) : (
                          <LastText>
                            {contact.latest_message[0]?.message}
                          </LastText>
                        )}
                      </ListItemContent>
                      <RightList className="right-list">
                        <LastTextTime hasUnread={contact.seen === "unread"}>
                          {moment(
                            Number(contact.latest_message[0]?.timestamp) * 1000
                          ).calendar(null, {
                            sameDay: "[Today]",
                            nextDay: "[Tomorrow]",
                            nextWeek: "DD/MM/YYYY",
                            lastDay: "[Yesterday]",
                            lastWeek: "DD/MM/YYYY",
                            sameElse: "DD/MM/YYYY",
                          })}
                        </LastTextTime>
                        {contact.seen === "unread" && (
                          <UnreadIcon hasUnread={true}>1</UnreadIcon>
                        )}
                      </RightList>
                    </ContentBox>
                  </CustomListItem>
                );
              })}
            </ContactListDiv>
          </LeftCard>
        </CardContainer>
        <InnerContainer chatSelected={selectedContact !== null}>
          {selectedContact ? (
            <>
              <ProfileHeader
                className="profile-header"
                onClick={toggleProfileInfo}
              >
                <Profile className="profile-name">
                  <SelectedProfile
                    className="selected-profile"
                    src={selectedContact.profilePic || userImg}
                  />
                  <Middle className="middle">
                    <Profilename className="profile-name">
                      {selectedContact.userName} 
                    </Profilename>
                  </Middle>
                </Profile>
                {!profileInfoOpen && (
                  <RightIcon className="right-icon" windowWidth={windowWidth}>
                    <StrokeImage src={strokeIcon} />
                    <VectorImage src={vectorIcon} />
                    <SearchI src={searchChats} />
                  </RightIcon>
                )}
              </ProfileHeader>
              <MessageList
                messageList={messageList[0]?.chats}
                ref={messageContainerRef}
              />

              <BottomCard>
                <FirstBottom>
                  {/* <EmojiPickerContainer>
                    {pickerVisible && (
                      <EmojiPicker
                        onEmojiClick={onEmojiClick}
                        pickerStyle={{
                          position: "absolute",
                        }}
                      />
                    )}
                  </EmojiPickerContainer> */}
                  <EmojiImage
                    src={groupIcon}
                    onClick={() => togglePicker(!pickerVisible)}
                  />
                  {/* <PlusB className="plus-B"> */}
                  <EmojiImage
                    src={plusIcon}
                    onClick={() => setMenuOpen(!menuOpen)} // Toggle the menu state when PlusImage is clicked
                  />

                  {/* <MenuOptions className="menu-option" menuOpen={menuOpen}>
                      <button onClick={handleImageClick}>Image</button>
                      <button onClick={handleVideoClick}>Video</button>
                    </MenuOptions>
                  </PlusB> */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </FirstBottom>
                <SecondBottom>
                  <MessageInput
                    placeholder="Write a message"
                    value={text}
                    onKeyDown={(e) => setMessageText(e.target.value)}
                    onChange={(e) => setText(e.target.value)}
                  />
                </SecondBottom>
                <ThirdBottom>
                  {/* <AudioImage src={audioIcon} /> */}
                  <button onClick={sendMessage}>
                    <img src={sendIcon} alt="send-button" />
                  </button>
                </ThirdBottom>
              </BottomCard>
            </>
          ) : (
            <WelcomePage>
              <Typography>Select a contact to start a conversation.</Typography>
            </WelcomePage>
          )}
        </InnerContainer>

        <ProfileInfoContainer
          profileInfoOpen={profileInfoOpen}
          windowWidth={windowWidth}
        >
          <ContactDetails className="contact-details">
            {profileInfoOpen && (
              <>
                <CloseIcon
                  src={closeIcon}
                  alt="Close"
                  onClick={closeProfileInfo}
                />

                <Frame>
                  {selectedContact && (
                    <>
                      <ContactProfilePic
                        src={selectedContact.profilePic}
                        onClick={() =>
                          openProfileZoomView(selectedContact?.profilePic)
                        }
                      />
                      {profileZoomViewOpen && (
                        <FullScreenImage
                          imageUrl={zoomedProfilePic}
                          onClose={closeProfileZoomView}
                        />
                      )}

                      <Contactname>{selectedContact.name}</Contactname>
                      <Contactphone>{selectedContact.number}</Contactphone>
                    </>
                  )}

                  <Line src={LineICon} />
                </Frame>
                <MiddleFrame>
                  <div
                    style={{
                      color: "#180a29",
                      fontSize: "20px",
                      fontWeight: 500,
                      letterSpacing: "0.4px",
                      position: "absolute",
                    }}
                  >
                    Media
                  </div>

                  <Seeall src={SeeallIcon} />
                </MiddleFrame>
                <ImageBox>
                  <Image1 src={Rectangle15} />
                  <Image2 src={Rectangle16} />
                  <Image3Container>
                    <Image3 src={Rectangle17} alt="Image 3" />
                    <Imageinside3 src={plusTenIcon} alt="Image Inside 3" />
                  </Image3Container>
                </ImageBox>
                <LowerFrame>
                  <div
                    style={{
                      color: "#180a29",
                      fontSize: "20px",
                      fontWeight: 500,
                      letterSpacing: "0.4px",
                      position: "absolute",
                    }}
                  >
                    Files
                  </div>

                  <Seeall src={SeeallIcon} />
                </LowerFrame>
                <FileInfo>
                  {selectedContact?.files?.map((file, index) => (
                    <FileContainer key={index}>
                      <FileIconContainer>
                        <FileIcon src={file.fileicon} alt="File Icon" />
                      </FileIconContainer>

                      <FileDetails>
                        <FileName>{file.filename}</FileName>
                        <FileSize>{file.filesize}</FileSize>
                      </FileDetails>
                    </FileContainer>
                  ))}
                </FileInfo>
                <StarredMsgHeading>
                  <div
                    style={{
                      color: "#180a29",
                      fontSize: "18px",
                      fontWeight: 500,
                      letterSpacing: "0.4px",
                      position: "absolute",
                    }}
                  >
                    Starred Messages
                  </div>

                  <Seeall src={SeeallIcon} />
                </StarredMsgHeading>
              </>
            )}

            {profileInfoOpen && <div></div>}
          </ContactDetails>
        </ProfileInfoContainer>
      </Container>
    </>
  );
};

export default Dashboard;
