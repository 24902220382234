import React from "react";
import socketio from "socket.io-client";
// import { env } from "../env";
import { SOCKET_URL } from "./constants/Statics"

// let hostD = "https://admin.pepesibubbles.com/";

export const socket = socketio.connect(SOCKET_URL);
export const SocketContext = React.createContext();
// import { env } from "../env";




